<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="渠道名称">
          <el-input v-model="searchData.channelName" size="small" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="负责人手机号">
          <el-input v-model="searchData.chargeMobile" size="small" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>
    <el-button
      v-checkbtn="isShowBtn(AUTH_BTN.channel_list_add)"
      style="margin-bottom:20px"
      type="primary"
      icon="el-icon-plus"
      size="small"
      @click="$router.push({ name: 'ChannelAdd' })"
      >新增
    </el-button>
    <tp-table
      :isNeedSerialNumber="true"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="pageSize"
    />
    <!-- 二维码弹窗 -->
    <el-dialog :title="`渠道名称：${channelName}`" :visible.sync="dialogVisible" width="700px">
      <el-divider content-position="center">二维码</el-divider>
      <Qrcode ref="code"></Qrcode>
    </el-dialog>
    <!-- 重置 -->
    <el-dialog title="重置密码" :visible.sync="dialogVisiblePw" width="700px">
      <el-form ref="form" :model="resetPwData" label-width="80px">
        <el-form-item label="新密码">
          <el-input style="width:300px" v-model="resetPwData.password" size="small" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="" size="small" @click="dialogVisiblePw = false">取消</el-button>
          <el-button type="primary" size="small" @click="savePw">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin'
import { getListAPI, deleteChannelAPI, changeStatusAPI, resetPwAPI } from './api'
import Qrcode from '@/components/qrcode/qrcode.vue'
const columns = [
  {
    label: '操作人',
    prop: 'actionUser',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['actionUser']?.['userName']}</p>
    }
  },
  {
    label: '渠道名称',
    prop: 'channelName',
    minWidth: '150',
    customRender(h, row) {
      return <p>{row['channelName']}</p>
    }
  },
  {
    label: '渠道代码',
    prop: 'channelCode',
    minWidth: '160',
    customRender(h, row) {
      return <p>{row['channelCode']}</p>
    }
  },

  {
    label: '负责人',
    prop: 'chargeName',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['chargeName']}</p>
    }
  },
  {
    label: '手机号',
    prop: 'chargeMobile',
    minWidth: '130',
    customRender(h, row) {
      return <p>{row['chargeMobile']}</p>
    }
  },
  {
    label: '状态',
    prop: 'status',
    minWidth: '120',
    customRender(h, row) {
      return (
        <div>
          <el-switch
            disabled={!this.isShowBtn(this.AUTH_BTN.channel_list_status)}
            class="switch"
            onChange={() => this.changeStatus(row)}
            v-model={row['status']}
            active-value={1}
            inactive-value={0}
            active-text="启用"
            inactive-text="禁用"
          ></el-switch>
        </div>
      )
    }
  },
  {
    label: '发现页',
    prop: '',
    minWidth: '110',
    customRender(h, row) {
      return <p></p>
    }
  },
  {
    label: '创建时间',
    prop: 'createTime',
    minWidth: '180',
    customRender(h, row) {
      return <p>{row['createTime']}</p>
    }
  },
  {
    label: '操作',
    minWidth: '320',
    fixed: 'right',
    customRender(h, row) {
      return (
        <div>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.channel_list_reset)}
            underline={false}
            type="primary"
            onClick={() => this.resetPw(row['id'])}
          >
            重置密码
          </el-link>
          <el-link
            style="margin-left:10px;"
            disabled={!this.isShowBtn(this.AUTH_BTN.channel_list_edit)}
            underline={false}
            type="primary"
            onClick={() => this.$router.push({ name: 'ChannelEdit', params: { id: row['id'] } })}
          >
            编辑
          </el-link>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.channel_list_del)}
            style="margin-left:10px;"
            underline={false}
            type="primary"
            onClick={() => this.handleDel(row)}
          >
            删除
          </el-link>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.channel_list_copy)}
            style="margin-left:10px;"
            underline={false}
            type="primary"
            onClick={() => this.copyLink(row['channelUrl'])}
          >
            复制链接
          </el-link>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.channel_list_code)}
            style="margin-left:10px;"
            underline={false}
            type="primary"
            onClick={() => this.showCode(row)}
          >
            查看二维码
          </el-link>
        </div>
      )
    }
  }
]
export default {
  name: 'List',
  mixins: [authBtnMixin],
  components: { Qrcode },
  data() {
    return {
      dialogVisiblePw: false,
      dialogVisible: false,
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: [],
      searchData: {
        channelName: '',
        chargeMobile: ''
      },
      channelName: '',
      url: '',
      resetPwData: {
        password: '',
        id: ''
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPage() {
      this.getList()
    },
    pageSize() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  },

  methods: {
    async getList() {
      const { channelName, chargeMobile } = this.searchData
      let params = { channelName, chargeMobile, page: this.currentPage, pageSize: this.pageSize }
      const res = await getListAPI(params)
      this.list = res.list || []
      this.total = res.total || 0
    },
    // 删除
    handleDel({ id }) {
      this.$confirm('此操作将永久删除该渠道, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteChannelAPI(id).then(() => {
            this.getList()
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 启用/禁用
    changeStatus(row) {
      let { id, status } = row
      let data = { id, status: status == 0 ? 0 : 1 }
      let statusDesc = status == 0 ? '禁用' : '启用'

      this.$confirm(`此操作将该渠道${statusDesc}吗, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          row.status = status == 0 ? 0 : 1
          changeStatusAPI(data).then(() => {
            this.getList()
            this.$message.success('操作成功')
          })
        })
        .catch(() => {
          row.status = status == 1 ? 0 : 1
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    showCode(row) {
      const { channelUrl, channelName } = row
      this.dialogVisible = true
      this.channelName = channelName
      this.$nextTick(function() {
        this.$refs.code.QRlink = channelUrl
        this.$refs.code.getQRcode()
      })
    },
    copyLink(url) {
      this.$copyText(url).then(
        () => {
          this.$message.success('复制成功')
        },
        () => {
          this.$message.error('复制失败')
        }
      )
    },
    resetPw(id) {
      this.$set(this.resetPwData, 'id', id)
      this.dialogVisiblePw = true
    },
    savePw() {
      resetPwAPI(this.resetPwData).then(() => {
        this.dialogVisiblePw = false
        this.$message.success('重置成功')
        this.getList()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .el-divider--horizontal {
    margin: 0 0 20px;
  }

  .el-form {
    .el-form-item {
      margin-right: 35px;
    }
  }
  .tp-table {
    .el-table__fixed-right {
      height: 100% !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  .el-dialog__body {
    padding: 20px 20px 50px 20px;
  }
  /* switch按钮样式 */
  .switch .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }

  /*打开时文字位置设置*/
  .switch .el-switch__label--right {
    z-index: 1;
  }

  /* 调整打开时文字的显示位子 */
  .switch .el-switch__label--right span {
    margin-left: 5px;
    margin-right: 10px;
  }

  /*关闭时文字位置设置*/
  .switch .el-switch__label--left {
    z-index: 1;
  }

  /* 调整关闭时文字的显示位子 */
  .switch .el-switch__label--left span {
    margin-left: 20px;
  }

  /*显示文字*/
  .switch .el-switch__label.is-active {
    display: block;
  }

  /* 调整按钮的宽度 */
  .switch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}
</style>
